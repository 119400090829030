import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import sanitizeFilename from "../../Functions/sanitizeFilename";

export const amountFormat = (value, formattor) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		let decimal = formattor.decimalPoint != null || formattor.decimalPoint !== undefined ? formattor.decimalPoint : 2;
		if (formattor.format === "round") {
			retValue = Math.round(Number(value));
		} else {
			retValue = Number(value).toFixed(decimal);
		}
		retValue = Intl.NumberFormat("en-US", {
			minimumFractionDigits: decimal,
		}).format(retValue);
		if (formattor.unit) {
			retValue = retValue + " " + formattor.unit;
		}
		if (formattor.displaySign) {
			retValue = "$" + retValue;
		}
	}
	return retValue;
};

export const percentageFormat = (value, formattor) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		let decimal = formattor.decimalPoint != null || formattor.decimalPoint != undefined ? formattor.decimalPoint : 2;
		if (formattor.format == "round") {
			retValue = Math.round(Number(value));
		} else {
			retValue = Number(value).toFixed(decimal);
		}
		retValue = Intl.NumberFormat("en-US", {
			minimumFractionDigits: decimal,
		}).format(retValue);
		if (formattor.displaySign) {
			retValue = retValue + "%";
		}
	}
	return retValue;
};

export const booleanFormat = (value, formattor) => {
	let retValue = value;

	if (value !== null && value !== undefined) {
		if (value) {
			retValue = <FontAwesomeIcon icon={formattor.iconIfTrue ?? "check"} size={formattor.size ?? "1x"} style={{ color: formattor.colorIfTrue ?? "#22d628" }} />;
		} else if (formattor.iconIfFalse) {
			retValue = <FontAwesomeIcon icon={formattor.iconIfFalse} size={formattor.size ?? "1x"} style={{ color: formattor.colorIfFalse ?? "#FF8888" }} />;
		} else {
			retValue = "";
		}
	}
	return retValue;
};

export const dateFormat = (value, format) => {
	if (value === null || value === undefined || value === "N/A") {
		return value;
	}

	// Ensure value is a Date object
	if (!(value instanceof Date)) {
		value = new Date(value);
	}

	// Check for invalid date
	if (isNaN(value)) {
		return value;
	}

	const padZero = (num, length = 2) => String(num).padStart(length, "0");

	const monthsFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	const weekdaysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	const weekdaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	const replacements = {
		YYYY: value.getFullYear(),
		YY: String(value.getFullYear()).slice(-2),
		MMMM: monthsFull[value.getMonth()],
		MMM: monthsShort[value.getMonth()],
		MM: padZero(value.getMonth() + 1),
		M: value.getMonth() + 1,
		DD: padZero(value.getDate()),
		D: value.getDate(),
		dddd: weekdaysFull[value.getDay()],
		ddd: weekdaysShort[value.getDay()],
		HH: padZero(value.getHours()),
		H: value.getHours(),
		hh: padZero(value.getHours() % 12 || 12),
		h: value.getHours() % 12 || 12,
		mm: padZero(value.getMinutes()),
		m: value.getMinutes(),
		ss: padZero(value.getSeconds()),
		s: value.getSeconds(),
		A: value.getHours() >= 12 ? "PM" : "AM",
		a: value.getHours() >= 12 ? "pm" : "am",
	};

	// Define existing format types and their corresponding format strings
	const predefinedFormats = {
		"full date": "MMMM D, YYYY h:mm A", // e.g., April 15, 2023 1:45 PM
		"date only": "MMMM D, YYYY", // e.g., April 15, 2023
		"short date": "MM/DD/YYYY", // e.g., 04/15/2023
		// Add more predefined types if needed
	};

	let formatString = "";

	// Determine if 'format' is an object with 'type' or a format string
	if (typeof format === "object" && format !== null && "type" in format) {
		const typeValue = format.type;
		const typeKey = typeValue.toLowerCase();

		if (typeKey in predefinedFormats) {
			formatString = predefinedFormats[typeKey];
		} else {
			// Assume typeValue is a custom format string
			formatString = typeValue;
		}
	} else if (typeof format === "string") {
		// Directly pass a format string
		formatString = format;
	} else {
		console.warn(`Invalid format parameter. Expected a format string or an object with a 'type' property. Falling back to default format.`);
		formatString = predefinedFormats["full date"];
	}

	// Handle escaped text within square brackets
	const escapeRegex = /\[([^\]]+)\]/g;
	const escapedText = [];
	let match;
	while ((match = escapeRegex.exec(formatString)) !== null) {
		escapedText.push(match[1]);
	}

	// Remove escaped parts from formatString
	const formatStringWithoutEscapes = formatString.replace(escapeRegex, "");

	// Create a regex pattern to match all tokens
	const tokenRegex = new RegExp(Object.keys(replacements).join("|"), "g");

	// Replace tokens in the format string with actual values
	let formattedDate = formatStringWithoutEscapes.replace(tokenRegex, (match) => replacements[match]);

	// Re-insert escaped text
	let escapeIndex = 0;
	formattedDate = formattedDate.replace(/\[([^\]]+)\]/g, () => escapedText[escapeIndex++]);

	return formattedDate;
};

export const linkFormat = (value, source, formattor) => {
	let retValue = value;

	if (value !== null && value !== undefined && value !== "N/A" && source.trim() === "Sycle") {
		retValue = value ? (
			<a href={`${formattor.linkBase.trim() + value.trim()}`} rel="noopener noreferrer" target="_blank">
				{" "}
				{value} <FontAwesomeIcon icon={"arrow-up-right-from-square"} />
			</a>
		) : (
			""
		);
	}
	return retValue;
};

export const buttonFormat = (value, formattor, handleClick) => {
	let retValue = value;
	if (value !== null && value !== undefined) {
		if (formattor.buttonPurpose === "opensOffcanvas") {
			retValue = (
				<Button type="button" variant={formattor.buttonVariant} className={formattor.buttonClasses} onClick={handleClick}>
					{formattor.buttonIcon && <FontAwesomeIcon icon={formattor.buttonIcon} />} {formattor.buttonText ?? value}
				</Button>
			);
		} else {
			// Handle other purposes or default rendering
		}
	}
	return retValue;
};

export const isEmpty = (value) => {
	// Check for null or undefined
	if (value == null) {
		return true;
	}

	// Check if value is a string
	if (typeof value === "string") {
		return value.trim().length === 0;
	}

	// Check if value is an array
	if (Array.isArray(value)) {
		return value.length === 0;
	}

	// Check if value is an object
	if (typeof value === "object") {
		return Object.keys(value).length === 0;
	}

	// If it's none of the above, it's not considered empty
	return false;
};

export const updateFileName = (fileName, dataObject, joinSymbol = "-") => {
	let excelFileName = fileName;
	if (!isEmpty(dataObject)) {
		const values = Object.values(dataObject)
			.filter((value) => value) // Exclude empty or undefined values
			.map((value) => value.toString().replace(/\s+/g, "")); // Remove spaces and lowercase each value

		if (excelFileName) {
			excelFileName = [excelFileName, ...values].join(joinSymbol);
		} else {
			excelFileName = [...values].join(joinSymbol);
		}
	}
	return sanitizeFilename(excelFileName);
};
