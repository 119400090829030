import { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import moment from "moment";
import MultiSelect from "./multiSelect";
import PropTypes from "prop-types";
import DoubleDateDropdown from "./doubleDateDropdown";
import { apiRequest } from "./api/Request";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/SiteWideFilter.css";

const SitewideFilter = ({ onClickApply, onSortingChange, onAvailableGroupingChange, onGroupingChange, availableGroupings, selectedSort, selectedGrouping, filtersEnabled, ...props }) => {
	const location = useLocation();
	const pathname = location.pathname.toLowerCase();

	const isDashboard = pathname.includes("/dashboard");
	const isKPIReport = pathname.includes("/reports/kpimanagement");
	const isFinance = pathname.includes("/finance");
	const isSIPReport = pathname.includes("/finance/sip");

	const navigate = useNavigate();

	const onDashboardChange = (newDashboard) => {
		sessionStorage.setItem("selectedDashboard", JSON.stringify(newDashboard));
		navigate(`/Dashboard/${newDashboard}`);
	};

	let today = moment();

	let initialStartDate = moment();
	let initialEndDate = moment();

	// const storedStartDate = JSON.parse(sessionStorage.getItem("StartDate"));
	const storedStartDate = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.startDate;
	// const storedEndDate = JSON.parse(sessionStorage.getItem("EndDate"));
	const storedEndDate = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.endDate;

	if (storedStartDate) {
		initialStartDate = moment(storedStartDate).toDate();
	} else {
		if (today.date() === 1) {
			// It's the first day of the month
			initialStartDate = today.clone().subtract(1, "month").startOf("month").toDate();
		} else {
			initialStartDate = today.clone().startOf("month").toDate();
		}
	}

	if (storedEndDate) {
		initialEndDate = moment(storedEndDate).toDate();
	} else {
		if (today.date() === 1) {
			// It's the first day of the month
			initialEndDate = today.clone().subtract(1, "month").endOf("month").toDate();
		} else {
			initialEndDate = today.clone().subtract(1, "day").toDate();
		}
	}

	const storedSelectedSources = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.selectedSources;
	const initialSelectedSources = storedSelectedSources ? storedSelectedSources : [];

	const storedSelectedTags = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.selectedTags;
	const initialSelectedTags = storedSelectedTags ? storedSelectedTags : [];

	if (isKPIReport) {
		if (today.date() === 1) {
			initialStartDate = moment(storedStartDate).subtract(1, "month").startOf("month").toDate();
			initialEndDate = moment(storedStartDate).subtract(1, "month").endOf("month").toDate();
		} else {
			initialStartDate = moment(storedStartDate).startOf("month").toDate();
			initialEndDate = moment(storedStartDate).endOf("month").toDate();
		}
	} else if(isSIPReport){
		initialStartDate = today.clone().subtract(1,"month").startOf("month").toDate();
		initialEndDate = today.clone().subtract(1, "month").endOf("month").toDate();
	}

	const [foundSystemVariables, setFoundSystemVariables] = useState(JSON.parse(localStorage.getItem("systemVariables")));
	const [foundRoleId, setFoundRoleId] = useState("");
	const [acquisitionViewerRoles, setAcquisitionViewerRoles] = useState([]);
	const [reportsWithoutDateFilters, setReportsWithoutDateFilters] = useState([]);

	const [filterStartDate, setFilterStartDate] = useState(initialStartDate);
	const [filterEndDate, setFilterEndDate] = useState(initialEndDate);
	const [clinics, setClinics] = useState([]);
	const [uniqueSources, setUniqueSources] = useState([]);
	const [uniqueClinicTags, setUniqueClinicTags] = useState([]);
	const [selectedSources, setSelectedSources] = useState(initialSelectedSources);
	const [selectedTags, setSelectedTags] = useState(initialSelectedTags.map((tag) => tag.tagName || tag));
	const [selectedClinics, setSelectedClinics] = useState([]);
	const [providers, setProviders] = useState([]);
	const [selectedProviders, setSelectedProviders] = useState([]);
	const [filteredProviders, setFilteredProviders] = useState([]);
	const [availableDashboards, setAvailableDashboards] = useState([]);
	const [selectedDashboard, setSelectedDashboard] = useState(JSON.parse(sessionStorage.getItem("selectedDashboard")));
	const [sortingType, setSortingType] = useState(selectedSort);
	const [groupingType, setGroupingType] = useState(selectedGrouping);
	const [availableFilters, setAvailableFilters] = useState();
	const [availableGrouping, setAvailableGrouping] = useState(availableGroupings);
	const [filtersDisabled, setFiltersDisabled] = useState(false);

	const [numberOfInitialRenders, setNumberOfInitialRenders] = useState(0);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const dropdownRef = useRef(null);

	const [isInitializing, setIsInitializing] = useState(true); // Initialization flag
	const [isUserChangingClinics, setIsUserChangingClinics] = useState(false); // Tracks user changes to Clinics
	const [isUserChangingTags, setIsUserChangingTags] = useState(false); // Tracks user changes to Tags
	const [isUserChangingSources, setIsUserChangingSources] = useState(false); // Tracks user changes to Sources

	const defaultTagStyle = {
		fontSize: "10px",
		paddingTop: "3px",
		paddingBottom: "3px",
		paddingRight: "7px",
		paddingLeft: "7px",
		borderRadius: "5px",
		marginRight: "5px",
		backgroundColor: "#fff",
		color: "#222",
		border: "1px solid #222",
	};

	const tagOptions = [
		{
			id: "no_tags",
			name: "[No Acquisition]",
			tagStyle: { ...defaultTagStyle, ...{ backgroundColor: "#fff" } },
		},
		...uniqueClinicTags
			.sort((a, b) => a.tagName.localeCompare(b.tagName))
			.map((tag) => ({
				id: tag.tagName,
				name: tag.tagName,
				tagStyle: { ...defaultTagStyle, ...tag.tagStyle },
			})),
	];

	let onChangeFilterEnable = (isDisabled) => {
		setFiltersDisabled(isDisabled);
	};

	useEffect(() => {
		if (availableDashboards && availableDashboards.length > 0 && !selectedDashboard) {
			handleDashboardChange(availableDashboards[0].dashboardId);
		}
	}, [availableDashboards]);

	const handleDateChange = (date, side) => {
		if (side === "Start") {
			setFilterStartDate(date);
		} else {
			setFilterEndDate(date);
		}

		sessionStorage.setItem(side + "Date", JSON.stringify(date));
	};

	const handleSetSelectedClinics = (clinicIds) => {
		setSelectedClinics(clinicIds);
	};

	const handleSetSelectedProviders = (selectedProviders) => {
		setSelectedProviders(selectedProviders);
		sessionStorage.setItem("selectedProviders", JSON.stringify(selectedProviders));
	};

	useEffect(() => {
		// if (selectedProviders.length === filteredProviders.length && filteredProviders.length > 0) {
		if (selectedProviders.length === filteredProviders.length || selectedProviders.length === 0) {
			sessionStorage.setItem("allProvidersSelected", "true");
		} else {
			sessionStorage.setItem("allProvidersSelected", "false");
		}
	}, [selectedProviders, filteredProviders]);

	const InitializeFilters = async () => {
		setIsInitializing(true);
		// Check for existing local storage values
		const systemVariables = localStorage.getItem("systemVariables");
		let sysVars;

		if (!systemVariables) {
			// Fetch system variables from the API
			const variablesRes = await apiRequest(process.env.REACT_APP_API_URL, `Dashboard/systemVariables`, "GET");
			sysVars = variablesRes.data;
			localStorage.setItem("systemVariables", JSON.stringify(sysVars));
			setFoundSystemVariables(sysVars);
		} else {
			sysVars = JSON.parse(systemVariables);
			setFoundSystemVariables(sysVars);
		}

		// Use sysVars to find acquisitionViewerRoles
		const foundAcquisitionViewerVariable = sysVars.find((sys) => sys.variableName === "acquisitionViewer");

		if (foundAcquisitionViewerVariable) {
			let foundAcquisitionViewerRoles = foundAcquisitionViewerVariable.variableValue;

			// If variableValue is a JSON string, parse it
			try {
				foundAcquisitionViewerRoles = foundAcquisitionViewerRoles.split(",").map((v) => Number(v));
			} catch (e) {
				console.error("Failed to parse acquisitionViewerRoles:", e);
			}

			setAcquisitionViewerRoles(foundAcquisitionViewerRoles);
		} else {
			console.warn("acquisitionViewer variable not found in system variables.");
			setAcquisitionViewerRoles([]);
		}

		const foundReportsWithoutDateFiltersVariable = sysVars.find((sys) => sys.variableName === "reportsWithoutDateFilters");
		if (foundReportsWithoutDateFiltersVariable) {
			let foundReportsWithoutDateFilters = foundReportsWithoutDateFiltersVariable.variableValue.split(",");

			setReportsWithoutDateFilters(foundReportsWithoutDateFilters);
		} else {
			console.warn("reportsWithoutDateFilters variable not found in system variables.");
			setReportsWithoutDateFilters([]);
		}

		// Check for existing session storage values
		const sessionDashboards = sessionStorage.getItem("sessionDashboards");
		const sessionClinics = sessionStorage.getItem("sessionClinics");
		const sessionProviders = sessionStorage.getItem("sessionProviders");
		const storedSelectedClinics = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.clinics;

		const storedSelectedProviders = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.providers;
		const storedSelectedSources = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.selectedSources;
		const storedSelectedTags = JSON.parse(sessionStorage.getItem("sitewideFilters"))?.selectedTags;

		const userId = JSON.parse(localStorage.getItem("amplifi_user_data")).userId;

		const displayedRoleData = JSON.parse(localStorage.getItem("displayed_role_data"));
		const roleId = displayedRoleData.roleId;
		const foundRoleName = displayedRoleData.roleName;
		setFoundRoleId(roleId);

		// Fetch dashboards
		if (isDashboard) {
			if (!sessionDashboards) {
				const dashboardsRes = await apiRequest(process.env.REACT_APP_API_URL, `Dashboard/fetch?roleId=${encodeURIComponent(roleId)}`, "GET");
				let dashboards = dashboardsRes.data;
				sessionStorage.setItem("sessionDashboards", JSON.stringify(dashboards));
				setAvailableDashboards(dashboards);
			} else {
				setAvailableDashboards(JSON.parse(sessionDashboards));
			}
		}

		let clinicData = null;

		if (!sessionClinics) {
			const clinicsRes = await apiRequest(process.env.REACT_APP_API_URL, `Dashboard/getClinics?userId=${encodeURIComponent(userId)}&roleId=${encodeURIComponent(roleId)}`, "GET");

			clinicData = clinicsRes.data;
			sessionStorage.setItem("sessionClinics", JSON.stringify(clinicData));
		} else {
			clinicData = JSON.parse(sessionClinics);
		}

		let clinics = clinicData.map((clinic) => ({
			id: clinic.clinicKey,
			source: clinic.companySource,
			name: clinic.name,
			region: clinic.region,
			territory: clinic.territory,
			tags: clinic.tags,
		}));

		setClinics(clinics);

		let uniqueSourcesFromClinicsList = Array.from(new Set(clinics.filter((clinic) => clinic.name !== "N/A").map((clinic) => clinic.source)));

		let uniqueTagsMap = new Map();

		clinics.forEach((clinic) => {
			if (clinic.tags) {
				clinic.tags.forEach((tag) => {
					if (!uniqueTagsMap.has(tag.tagName)) {
						uniqueTagsMap.set(tag.tagName, tag.tagStyle);
					}
				});
			}
		});

		// Convert the Map to an array
		let uniqueTagsFromClinicsList = Array.from(uniqueTagsMap.entries()).map(([tagName, tagStyle]) => ({
			tagName,
			tagStyle,
		}));

		setUniqueSources(uniqueSourcesFromClinicsList);
		setUniqueClinicTags(uniqueTagsFromClinicsList);

		let providerData = null;

		if (!sessionProviders) {
			const providersRes = await apiRequest(
				process.env.REACT_APP_API_URL,
				"Dashboard/getProviders",
				"POST",
				clinics.map((clinic) => clinic.id)
			);

			providerData = providersRes.data;
			sessionStorage.setItem("sessionProviders", JSON.stringify(providerData));
		} else {
			providerData = JSON.parse(sessionProviders);
		}

		let providers = providerData.map((prov) => ({
			id: prov.staffKey,
			name: prov.name,
			clinics: prov.clinicKeys,
		}));

		setProviders(providers);

		// Use session storage values if they exist
		let useTheseSelectedClinics = storedSelectedClinics ? storedSelectedClinics : clinics.map((clinic) => clinic.id);
		let useTheseSelectedProviders = storedSelectedProviders ? storedSelectedProviders : providers.map((provider) => provider.id);
		let useTheseSelectedSources = storedSelectedSources ? storedSelectedSources : [];
		let useTheseSelectedTags = storedSelectedTags ? storedSelectedTags : [];

		setSelectedClinics(useTheseSelectedClinics);
		setSelectedProviders(useTheseSelectedProviders);
		setSelectedSources(useTheseSelectedSources);
		setSelectedTags(useTheseSelectedTags);

		if (availableDashboards.length > 0) {
			handleChangeFilters(selectedDashboard);
		}

		// Populate filteredProviders based on selectedClinics
		const initialFilteredProviders = providers.filter((provider) => provider.clinics.some((clinicId) => useTheseSelectedClinics.includes(clinicId)));
		setFilteredProviders(initialFilteredProviders);

		// Ensure selectedProviders are subsets of initialFilteredProviders
		const initialSelectedProvidersFiltered = useTheseSelectedProviders.filter((providerId) => initialFilteredProviders.some((provider) => provider.id === providerId));
		setSelectedProviders(initialSelectedProvidersFiltered);
		sessionStorage.setItem("selectedProviders", JSON.stringify(initialSelectedProvidersFiltered));

		// Apply initial filters
		const filterJSON = {
			startDate: filterStartDate,
			endDate: filterEndDate,
			clinics: useTheseSelectedClinics,
			providers: useTheseSelectedProviders, // Use the selectedProviders from sessionStorage
			selectedSources: useTheseSelectedSources,
			selectedTags: useTheseSelectedTags,
		};

		switch (selectedGrouping) {
			case "Clinic":
				filterJSON["groupByClinic"] = true;
				break;
			case "Region":
				filterJSON["groupByRegion"] = true;
				break;
			case "Territory":
				filterJSON["groupByTerritory"] = true;
				break;
			case "All":
				filterJSON["groupByAll"] = true;
				break;
			default:
				break;
		}

		onClickApply(filterJSON);

		setIsInitializing(false);
	};
	useEffect(() => {
		InitializeFilters();

		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDatePicker(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Handle filtering providers based on the selected clinics
	useEffect(() => {
		if ((isUserChangingClinics || isUserChangingSources || isUserChangingTags) && !isInitializing) {
			// Filter providers based on selectedClinics

			const newFilteredProviders = providers.filter((provider) => provider.clinics.some((clinicId) => selectedClinics.includes(clinicId)));

			setFilteredProviders(newFilteredProviders);

			// Auto-select all filtered providers
			const autoSelectedProviders = newFilteredProviders.map((provider) => provider.id);
			setSelectedProviders(autoSelectedProviders);
			sessionStorage.setItem("selectedProviders", JSON.stringify(autoSelectedProviders));

			// Reset the flag
			setIsUserChangingClinics(false);
			setIsUserChangingTags(false);
			setIsUserChangingSources(false);
		}
	}, [selectedClinics, providers, isInitializing]);

	const filteredClinics = useMemo(() => {
		let result = clinics;

		// Filter by tags
		if (selectedTags.length > 0) {
			result = result.filter((clinic) => {
				const hasTags = clinic.tags && clinic.tags.length > 0;
				const selectedNoTags = selectedTags.includes("no_tags");
				if (selectedNoTags && !hasTags) {
					return true;
				}
				if (hasTags) {
					return clinic.tags.some((tag) => selectedTags.includes(tag.tagName));
				}
				return false;
			});
		}

		// Filter by sources
		if (selectedSources.length > 0) {
			result = result.filter((clinic) => selectedSources.includes(clinic.source));
		}

		return result;
	}, [clinics, selectedTags, selectedSources]);

	useEffect(() => {
		if ((isUserChangingTags || isUserChangingSources) && !isInitializing) {
			// Find Clinics related to selected Tags

			const clinicsByTags = clinics.filter((clinic) => clinic.tags && clinic.tags.some((tag) => selectedTags.includes(tag.tagName))).map((clinic) => clinic.id);

			// Find Clinics related to selected Sources
			const clinicsBySources = clinics.filter((clinic) => selectedSources.length === 0 || selectedSources.includes(clinic.source)).map((clinic) => clinic.id);

			// Combine Clinics (Union)
			const combinedClinicIds = [...new Set([...clinicsByTags, ...clinicsBySources])];

			// Update selectedClinics

			setSelectedClinics(combinedClinicIds);
		}
	}, [selectedTags, selectedSources, clinics, isInitializing]);

	useEffect(() => {
		const filteredClinicIds = filteredClinics.map((clinic) => clinic.id);

		if (numberOfInitialRenders < 2) {
			setNumberOfInitialRenders(numberOfInitialRenders + 1);
			//setSelectedClinics(selectedClinics);
		} else {
			setSelectedClinics(filteredClinicIds);
		}
	}, [filteredClinics]);

	const handleSelectedClinics = (clinicIds) => {
		setIsUserChangingClinics(true);
		handleSetSelectedClinics(clinicIds);
	};

	const handleSourceChange = (sources) => {
		setIsUserChangingSources(true);
		setSelectedSources(sources);
	};

	const handleTagChange = (selectedTagIds) => {
		setIsUserChangingTags(true);
		setSelectedTags(selectedTagIds);
	};

	const handleSelectedProviders = (providerIds) => {
		handleSetSelectedProviders(providerIds);
	};

	const handleDashboardChange = (dashboard) => {
		onDashboardChange(dashboard);

		handleChangeFilters(dashboard);
	};

	const handleChangeFilters = (dashboardId) => {
		const selectedBoard = availableDashboards.find((d) => d.dashboardId === Number(dashboardId));

		const dashboardConfig = JSON.parse(selectedBoard.config);

		sessionStorage.setItem("availableFilters", dashboardConfig.sitewideFilters);
		setAvailableFilters(dashboardConfig.sitewideFilters);

		sessionStorage.setItem("availableGroupings", dashboardConfig.grouping ? JSON.stringify(dashboardConfig.grouping) : null);
		setAvailableGrouping(dashboardConfig.grouping ? dashboardConfig.grouping : null);
		onAvailableGroupingChange(dashboardConfig.grouping ? dashboardConfig.grouping : null);

		if (dashboardConfig.grouping == null) {
			handleGroupingChange("Clinic");
		}
	};

	const handleSortingChange = (sorting) => {
		setSortingType(sorting);
		onSortingChange(sorting);
	};

	const handleGroupingChange = (grouping) => {
		setGroupingType(grouping);
		onGroupingChange(grouping);
	};

	const handleClickApply = () => {
		const filterJSON = {
			startDate: filterStartDate,
			endDate: filterEndDate,
			clinics: selectedClinics,
			providers: selectedProviders,
			selectedSources: selectedSources,
			selectedTags: selectedTags,
		};

		//sessionStorage.setItem("sitewideFilters", filterJSON);

		switch (selectedGrouping) {
			case "Clinic":
				filterJSON["groupByClinic"] = true;
				break;
			case "Region":
				filterJSON["groupByRegion"] = true;
				break;
			case "Territory":
				filterJSON["groupByTerritory"] = true;
				break;
			case "All":
				filterJSON["groupByAll"] = true;
				break;
			default:
				break;
		}

		onClickApply(filterJSON);
	};

	const setPredefinedRange = (range) => {
		let start, end;

		switch (range) {
			case "Today":
				start = moment().toDate();
				end = moment().toDate();
				break;
			case "Month to Date":
				if (today.date() === 1) {
					// First day of the month, use Last Month
					start = today.clone().subtract(1, "month").startOf("month").toDate();
					end = today.clone().subtract(1, "month").endOf("month").toDate();
				} else {
					start = today.clone().startOf("month").toDate();
					end = today.clone().subtract(1, "day").toDate();
				}
				break;
			case "Quarter to Date":
				if (today.isSame(today.clone().startOf("quarter"), "day")) {
					// First day of the quarter, use Last Quarter
					start = today.clone().subtract(1, "quarter").startOf("quarter").toDate();
					end = today.clone().subtract(1, "quarter").endOf("quarter").toDate();
				} else {
					start = today.clone().startOf("quarter").toDate();
					end = today.clone().subtract(1, "day").toDate();
				}
				break;
			case "Year to Date":
				if (today.isSame(today.clone().startOf("year"), "day")) {
					// First day of the year, use Last Year
					start = today.clone().subtract(1, "year").startOf("year").toDate();
					end = today.clone().subtract(1, "year").endOf("year").toDate();
				} else {
					start = today.clone().startOf("year").toDate();
					end = today.clone().subtract(1, "day").toDate();
				}
				break;
			case "Last Month":
				start = moment().subtract(1, "month").startOf("month").toDate();
				end = moment().subtract(1, "month").endOf("month").toDate();
				break;
			case "Last Quarter":
				start = moment().subtract(1, "quarter").startOf("quarter").toDate();
				end = moment().subtract(1, "quarter").endOf("quarter").toDate();
				break;
			case "Last Year":
				start = moment().subtract(1, "year").startOf("year").toDate();
				end = moment().subtract(1, "year").endOf("year").toDate();
				break;
			case "Fiscal Year":
				start = moment()
					.month(3)
					.date(1)
					.startOf("day")
					.subtract(moment().month() < 3 ? 1 : 0, "year")
					.toDate();
				end = moment(start).add(1, "year").subtract(1, "day").endOf("day").toDate();
				break;
			case "Fiscal Year to Date":
				const fiscalYearStart = moment()
					.month(3)
					.date(1)
					.startOf("day")
					.subtract(moment().month() < 3 ? 1 : 0, "year");
				if (today.isSame(fiscalYearStart, "day")) {
					// First day of the fiscal year, use Last Fiscal Year
					start = fiscalYearStart.clone().subtract(1, "year").toDate();
					end = fiscalYearStart.clone().subtract(1, "day").toDate();
				} else {
					start = fiscalYearStart.toDate();
					end = today.clone().subtract(1, "day").endOf("day").toDate();
				}
				break;
			case "Last Fiscal Year":
				start = moment()
					.month(3)
					.date(1)
					.startOf("day")
					.subtract(moment().month() < 3 ? 2 : 1, "year")
					.toDate();
				end = moment(start).add(1, "year").subtract(1, "day").endOf("day").toDate();
				break;
			default:
				start = moment().toDate();
				end = moment().toDate();
		}

		handleDateChange(start, "Start");
		handleDateChange(end, "End");
	};

	const filtersContainFilter = (filter) => {
		return availableFilters ? (availableFilters.includes(filter) ? true : false) : filter !== "sort" ? true : false;
	};

	const shouldHideDateFilter = () => {
		const pathname = location.pathname.toLowerCase();

		if (reportsWithoutDateFilters.length > 0) {
			// Check if any report matches the pathname
			return reportsWithoutDateFilters.some((report) => pathname.includes(report.toLowerCase()));
		} else {
			// Default case: Do not hide the date filter
			return false;
		}
	};

	const applyDisabled = selectedClinics.length === 0 || filterStartDate == null || filterEndDate == null;

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			You must select a valid date range and at least one clinic.
		</Tooltip>
	);

	return (
		<>
			<Row className="px-2 mb-3">
				{/* Dashboard Selector */}
				{isDashboard && availableDashboards && (
					<Col md={2}>
						<Form.Select
							onChange={(e) => handleDashboardChange(e.target.value)}
							value={selectedDashboard}
							className="btn-outline-secondary-white-background smallerFont"
							disabled={!filtersEnabled}>
							{availableDashboards.map((ad) => (
								<option key={ad.dashboardId} value={ad.dashboardId}>
									{ad.dashboardName}
								</option>
							))}
						</Form.Select>
					</Col>
				)}

				{/* Filters Row */}
				<Col>
					<Row className="justify-content-end">
						{/* Date Picker */}
						{filtersContainFilter("date") && !shouldHideDateFilter() && (
							<Col md={{ span: 2 }} className="text-end" style={{ position: "relative" }}>
								<Button variant="outline-secondary-white-background" onClick={() => setShowDatePicker(!showDatePicker)} className="smallerFont" disabled={!filtersEnabled}>
									{moment(filterStartDate).format("MMM D, YYYY")} - {filterEndDate ? moment(filterEndDate).format("MMM D, YYYY") : ""}
								</Button>
								{showDatePicker && (
									<>
										<div
											ref={dropdownRef}
											style={{
												position: "absolute",
												top: "100%",
												left: 0,
												zIndex: 9999,
											}}>
											<DoubleDateDropdown
												startDate={filterStartDate}
												endDate={filterEndDate}
												setStartDate={handleDateChange}
												setEndDate={handleDateChange}
												setPredefinedRange={setPredefinedRange}
											/>
										</div>
									</>
								)}
							</Col>
						)}

						{/* Acquisition Tags */}
						{!isFinance && acquisitionViewerRoles.includes(foundRoleId) && (
							<Col md={{ span: 2 }}>
								<MultiSelect
									options={tagOptions}
									onChange={handleTagChange}
									selectedOptions={selectedTags}
									singularNoun={"Acquisition"}
									disabled={!filtersEnabled}
									renderOption={(option) => (
										<Badge bg="" style={option.tagStyle}>
											{option.name}
										</Badge>
									)}
								/>
							</Col>
						)}

						{/* Clinics Filter */}
						{!isFinance && filtersContainFilter("clinics") && (
							<Col md={{ span: 3 }}>
								<MultiSelect
									options={filteredClinics.map((clinic) => ({
										name: clinic.name,
										source: clinic.source,
										id: clinic.id,
										territory: clinic.territory,
										region: clinic.region,
										tags: clinic.tags,
									}))}
									groupingKeys={["territory", "region"]}
									sources={uniqueSources}
									onChange={handleSelectedClinics}
									selectedOptions={selectedClinics}
									sourcesToSelect={selectedSources}
									onSourceChange={handleSourceChange}
									singularNoun={"Clinic"}
									className="smallerFont"
									disabled={!filtersEnabled}
									displayInlineTags={selectedTags.length > 0}
								/>
							</Col>
						)}

						{/* Providers Filter */}
						{!isFinance && filtersContainFilter("providers") && (
							<Col md={{ span: 2 }}>
								<MultiSelect
									options={filteredProviders.map((provider) => ({
										id: provider.id,
										name: provider.name,
										clinics: provider.clinics,
									}))}
									onChange={handleSelectedProviders}
									selectedOptions={selectedProviders}
									singularNoun={"Provider"}
									disabled={!filtersEnabled}
								/>
							</Col>
						)}

						{/* Apply Button */}
						<Col md={{ span: 1 }}>
							{applyDisabled ? (
								<OverlayTrigger placement="left" overlay={renderTooltip}>
									<span className="d-inline-block w-100">
										<Button variant="outline-primary" onClick={handleClickApply} className="w-100" disabled={applyDisabled} style={{ pointerEvents: "none" }}>
											Apply
										</Button>
									</span>
								</OverlayTrigger>
							) : (
								<Button variant="outline-primary" onClick={handleClickApply} className="w-100" disabled={!filtersEnabled}>
									Apply
								</Button>
							)}
						</Col>
					</Row>
				</Col>
			</Row>

			{isDashboard && availableGrouping ? (
				<>
					<Row className={"px-2 mb-3"}>
						<Col md={{ offset: 4, span: 8 }}>
							<Row>
								<Col md={{ offset: 8, span: 2 }}>
									{availableGrouping ? (
										<Form.Select
											onChange={(e) => handleGroupingChange(e.target.value)}
											value={selectedGrouping}
											className="btn-outline-secondary-white-background smallerFont"
											disabled={!filtersEnabled}>
											{availableGrouping.map((ag, index) => {
												return (
													<option key={index} value={ag}>
														{"Group by " + ag}
													</option>
												);
											})}
										</Form.Select>
									) : (
										<></>
									)}
								</Col>
								<Col md={{ span: 2 }}>
									<Form.Select
										onChange={(e) => handleSortingChange(e.target.value)}
										value={selectedSort}
										className="btn-outline-secondary-white-background smallerFont"
										disabled={!filtersEnabled}>
										<option key={"alpha"} value={"alpha"}>
											{"Alphabetical"}
										</option>
										<option key={"asc"} value={"asc"}>
											{"Low-to-High"}
										</option>
										<option key={"goal"} value={"goal"}>
											{"%-to-Goal"}
										</option>
									</Form.Select>{" "}
								</Col>
							</Row>
						</Col>
					</Row>
				</>
			) : (
				<></>
			)}
		</>
	);
};

SitewideFilter.propTypes = {
	onClickApply: PropTypes.func.isRequired,
	onSortingChange: PropTypes.func,
	onAvailableGroupingChange: PropTypes.func,
	onGroupingChange: PropTypes.func,
	availableGroupings: PropTypes.array,
	selectedSort: PropTypes.string,
	selectedGrouping: PropTypes.string,
	filtersEnabled: PropTypes.bool,
};

export default SitewideFilter;
