import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Card, Spinner, Badge } from "react-bootstrap";
import { apiRequest } from "./api/Request";
import MultiSelect from "./multiSelect";
import { isEmpty } from "./common/Helper";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const GroupUsersEditor = ({ groupId, amplifiUsers, updateUsers }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [initialUserIds, setInitialUserIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // We will only update initialUserIds when the groupId changes,
    // ensuring that initialUserIds remains stable for a given group.
    useEffect(() => {
        let initialIds = [];
        if (amplifiUsers != null) {
            if (typeof amplifiUsers === "string") {
                initialIds = JSON.parse(amplifiUsers).map(Number);
            } else if (Array.isArray(amplifiUsers)) {
                initialIds = amplifiUsers.map(Number);
            }
        }
        setInitialUserIds(initialIds);
        setSelectedUsers(initialIds);
    }, []); // Only run when the groupId changes, not when amplifiUsers changes

    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true);
            const response = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/amplifi-users", "get");
            let users = response.data.map((user) => ({
                id: user.userId,
                name: user.firstName + " " + user.lastName + (isEmpty(user.payrollId) ? "" : " - " + user.payrollId),
                disabled: false,
                groups: JSON.parse(user.groupIds || "[]"),
            }));

            users.sort((a, b) => a.name.localeCompare(b.name));
            setAvailableUsers(users);
            setIsLoading(false);
        };
        fetchUsers();
    }, [groupId]);

    // If amplifiUsers changes (due to parent refresh), only update selectedUsers, do not reset initialUserIds.
    useEffect(() => {
        // Parse the current amplifiUsers but don't reset initialUserIds
        let currentUserIds = [];
        if (amplifiUsers != null) {
            if (typeof amplifiUsers === "string") {
                currentUserIds = JSON.parse(amplifiUsers).map(Number);
            } else if (Array.isArray(amplifiUsers)) {
                currentUserIds = amplifiUsers.map(Number);
            }
        }
        setSelectedUsers(currentUserIds);
    }, [amplifiUsers]);

    const handleUserSelection = (selectedUserIds) => {
        setSelectedUsers(selectedUserIds);
        updateUsers(selectedUserIds);
    };

    const handleRemoveUser = (id) => {
        const newSelectedUsers = selectedUsers.filter((userId) => userId !== id);
        setSelectedUsers(newSelectedUsers);
        updateUsers(newSelectedUsers);
    };

    // Calculate newly added and removed users
    const newlyAddedUserIds = selectedUsers.filter((id) => !initialUserIds.includes(id));
    const newlyRemovedUserIds = initialUserIds.filter((id) => !selectedUsers.includes(id));

    // Get details for selected and removed users
    const selectedUserDetails = selectedUsers
        .map((userId) => availableUsers.find((user) => user.id === userId))
        .filter(Boolean);

    const removedUserDetails = newlyRemovedUserIds
        .map((userId) => availableUsers.find((user) => user.id === userId))
        .filter(Boolean);

    // Combine for rendering
    const displayedUsers = [...selectedUserDetails, ...removedUserDetails];

    const renderBadge = (status) => {
        const addedBadgeStyle = {
            fontSize: "10px",
            padding: "3px 7px",
            borderRadius: "5px",
            marginLeft: "5px",
            backgroundColor: "#D9FBF2",
            color: "#3BAF8D",
            border: "1px solid #3BAF8D",
        };

        const removedBadgeStyle = {
            fontSize: "10px",
            padding: "3px 7px",
            borderRadius: "5px",
            marginLeft: "5px",
            backgroundColor: "#FFEFEF",
            color: "#D9534F",
            border: "1px solid #D9534F",
        };

        return (
            <Badge bg="" style={status === "ADDED" ? addedBadgeStyle : removedBadgeStyle}>
                {status}
            </Badge>
        );
    };

    return (
        <Row className="w-100">
            <Col>
                <Card className="mx-3">
                    <Card.Header>
                        <Row>
                            <Col>
                                <h5>User Mapping</h5>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                {isLoading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                ) : (
                                    <MultiSelect
                                        keepOpen={true}
                                        selectAllCheck={true}
                                        options={availableUsers}
                                        onChange={handleUserSelection}
                                        selectedOptions={selectedUsers}
                                        singularNoun="User"
                                        disabled={false}
                                    />
                                )}
                            </Col>
                            <Col md={6}>
                                <Card className="h-100">
                                    <Card.Header>Selected Users</Card.Header>
                                    <Card.Body>
                                        {isLoading ? (
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        ) : (
                                            <div className="selectedStaffOptions">
                                                {displayedUsers.map((user) => {
                                                    const isAdded = newlyAddedUserIds.includes(user.id);
                                                    const isRemoved = newlyRemovedUserIds.includes(user.id);

                                                    return (
                                                        <div key={user.id} className={`d-flex align-items-center mb-2 ${isRemoved ? "text-muted" : ""}`}>
                                                            {!isRemoved && (
                                                                <Button
                                                                    className="text-center me-2 noBorder"
                                                                    size="sm"
                                                                    variant="outline-danger"
                                                                    onClick={() => handleRemoveUser(user.id)}
                                                                >
                                                                    <FontAwesomeIcon icon="trash-xmark" />
                                                                </Button>
                                                            )}
                                                            <strong style={isRemoved ? { textDecoration: "line-through" } : {}}>{user.name}</strong>
                                                            {isAdded && renderBadge("ADDED")}
                                                            {isRemoved && renderBadge("REMOVED")}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

GroupUsersEditor.propTypes = {
    groupId: PropTypes.number.isRequired,
    amplifiUsers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.string]),
    updateUsers: PropTypes.func.isRequired,
};

export default GroupUsersEditor;
