import React, { useMemo, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import generateExcel from "zipcelx";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter, useExpanded } from "react-table";
import { amountFormat, dateFormat, updateFileName } from "./Helper";
import "../../Styles/Reports.css";

const Table = ({
	columns,
	data,
	totalAggregateRow,
	manualSortBy = false,
	allowGlobalFilter = false,
	globalFilterValue,
	updateGlobalFilterValue,
	activeOnlyFilter = false,
	activeOnly,
	filterList,
	viewDetailsBtn = false,
	viewDetails,
	deleteBtn = false,
	updateStatus,
	defaultSortBy,
	updateDefaultSortBy,
	statusField,
	pageIndexValue = 0,
	updatePageIndexValue,
	columnFilter,
	updateColumnFilter,
	configData,
	allowPagination,
	fileName,
	tableHeight,
	reduceWidth,
	expandedByDefault = false,
	exportAllRows = false,
	rowProps = () => ({}),
}) => {
	const location = useLocation();
	const pathname = location.pathname.toLowerCase();

	const isKpiManagement = pathname === "/reports/kpimanagement";
	const isFinance = pathname.includes("finance");

	const [columnFilterReport, setColumnFilterReport] = useState({});
	function DefaultColumnFilter({ column: { filterValue, setFilter, id, Header, filterInputType, preFilteredRows } }) {
		const options = useMemo(() => {
			if (filterInputType !== "select") {
				return [];
			}

			const options = [];
			preFilteredRows.forEach((row) => {
				if (row.values[id]) {
					options.push({
						label: row.values[id],
						value: row.values[id],
					});
				}
			});
			const uniqueObjectsOptions = options.filter((obj, index, self) => index === self.findIndex((t) => t.value === obj.value));
			return uniqueObjectsOptions;
		}, [filterInputType, id, preFilteredRows]);

		if (filterInputType === "select") {
			return (
				<select
					className="form-select form-select-sm"
					value={filterValue}
					onChange={(e) => {
						setFilter(e.target.value || undefined);
						if (updateColumnFilter) {
							updateColumnFilter(id, e.target.value);
						}
						updateColumnFilterReport(id, e.target.value);
						if (updatePageIndexValue) {
							updatePageIndexValue(0);
						}
					}}>
					<option value="">All</option>
					{options.map((option, i) => (
						<option key={i} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			);
		} else if (filterInputType === "check") {
			return (
				<input
					type="checkbox"
					checked={filterValue === "true"}
					className="form-check-input"
					onChange={(e) => {
						const value = e.target.checked ? "true" : undefined;
						setFilter(value);
						if (updateColumnFilter) {
							updateColumnFilter(id, value);
						}
						updateColumnFilterReport(id, e.target.value);
						if (updatePageIndexValue) {
							updatePageIndexValue(0);
						}
					}}
				/>
			);
		} else {
			return (
				<input
					type={filterInputType ? filterInputType : "text"}
					value={filterValue || ""}
					className="form-control form-control-sm"
					onChange={(e) => {
						setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
						if (updateColumnFilter) {
							updateColumnFilter(id, e.target.value);
						}
						updateColumnFilterReport(id, e.target.value);
						if (updatePageIndexValue) {
							updatePageIndexValue(0);
						}
					}}
					placeholder={`Search ${Header}...`}
				/>
			);
		}
	}

	let updateColumnFilterReport = (id, value) => {
		let obj = columnFilterReport;
		obj[id] = value;

		setColumnFilterReport(obj);
	};

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	function customFilterStartsWith(rows, id, filterValue) {
		return rows.reduce((filtered, row) => {
			const rowValue = row.values[id];
			const rowMatches = String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase());

			let matchingSubRows = [];

			if (row.subRows && row.subRows.length > 0) {
				matchingSubRows = customFilterStartsWith(row.subRows, id, filterValue);
			}

			if (rowMatches) {
				// Row matches, include all subRows
				filtered.push({
					...row,
					subRows: row.subRows,
				});
			} else if (matchingSubRows.length > 0) {
				// Row doesn't match, but some subRows match
				filtered.push({
					...row,
					subRows: matchingSubRows,
				});
			}
			return filtered;
		}, []);
	}

	function customFilterContains(rows, id, filterValue) {
		return rows.reduce((filtered, row) => {
			const rowValue = row.values[id];
			const rowMatches = String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());

			let matchingSubRows = [];

			if (row.subRows && row.subRows.length > 0) {
				matchingSubRows = customFilterContains(row.subRows, id, filterValue);
			}

			if (rowMatches) {
				// Row matches, include all subRows
				filtered.push({
					...row,
					subRows: row.subRows,
				});
			} else if (matchingSubRows.length > 0) {
				// Row doesn't match, but some subRows match
				filtered.push({
					...row,
					subRows: matchingSubRows,
				});
			}
			return filtered;
		}, []);
	}

	function customFilterEquals(rows, id, filterValue) {
		return rows.reduce((filtered, row) => {
			const rowValue = row.values[id];
			const rowMatches = String(rowValue).toLowerCase() === String(filterValue).toLowerCase();

			let matchingSubRows = [];

			if (row.subRows && row.subRows.length > 0) {
				matchingSubRows = customFilterEquals(row.subRows, id, filterValue);
			}

			if (rowMatches) {
				// Row matches, include all subRows
				filtered.push({
					...row,
					subRows: row.subRows,
				});
			} else if (matchingSubRows.length > 0) {
				// Row doesn't match, but some subRows match
				filtered.push({
					...row,
					subRows: matchingSubRows,
				});
			}
			return filtered;
		}, []);
	}

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: pageIndexValue,
				pageSize: allowPagination === "yes" ? 30 : data.length,
				sortBy: defaultSortBy ? defaultSortBy : [],
				globalFilter: globalFilterValue || "",
				filters: columnFilter || [],
				isExpanded: expandedByDefault,
			},
			defaultColumn,
			filterTypes: {
				startsWith: customFilterStartsWith,
				equals: customFilterEquals,
				contains: customFilterContains,
			},
			autoResetExpanded: false,
			manualPagination: allowPagination !== "yes",
			paginateExpandedRows: false,
			filterFromLeavesUp: false,
			getSubRows: (row) => row.subRows || [],
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		flatRows,
		pageCount,
		rows,

		state: { pageIndex, pageSize, globalFilter, sortBy },
	} = tableInstance;

	const hasAggregations = headerGroups[0].headers.some((column) => column.aggregate);

	const aggregates = useMemo(() => {
		let aggregateResults = {};
		if (hasAggregations) {
			headerGroups[0].headers.forEach((column) => {
				if (column.aggregate) {
					aggregateResults[column.id] = {
						sum: 0,
						count: 0, // Count is needed for averages
						average: 0,
						avgItems: [],
					};
				}
			});

			// Accumulate values
			rows.forEach((row) => {
				headerGroups[0].headers.forEach((column) => {
					const value = row.values[column.id];
					if (value === undefined || value === null) return;

					// Handle sum aggregation
					if (column.aggregate === "sum" || column.aggregate === "sum-quantity") {
						aggregateResults[column.id].sum += typeof value === "boolean" ? (value ? 1 : 0) : value;
					}

					// Handle average aggregation
					if (column.aggregate === "average" || column.aggregate === "dividedAverage") {
						let includeValue = true;

						// Check if includeRangeInAverage is specified
						if (column.includeRangeInAverage) {
							const [min, max] = column.includeRangeInAverage;
							includeValue = value >= min && value <= max;
						}

						if (includeValue) {
							aggregateResults[column.id].sum += value;
							aggregateResults[column.id].count += column.averageDivider ? row.values[column.averageDivider] : 1;

							if (column.averageOn) {
								const avgItemValue = row.values[column.averageOn];
								if (!aggregateResults[column.id].avgItems.includes(avgItemValue)) {
									aggregateResults[column.id].avgItems.push(avgItemValue);
								}
							}
						}
					}
				});
			});

			// Calculate averages from sums and counts
			Object.keys(aggregateResults).forEach((key) => {
				const column = headerGroups[0].headers.find((col) => col.id === key);
				if (column && (column.aggregate === "average" || column.aggregate === "dividedAverage")) {
					const result = aggregateResults[key];
					if (result.count > 0) {
						const divisor = result.avgItems.length > 0 ? result.avgItems.length : result.count;
						result.average = result.sum / divisor;
					} else {
						result.average = 0; // In case of no data
					}
				}
			});
		}

		return aggregateResults;
	}, [rows, headerGroups, hasAggregations]);

	const getDisplayValue = (column, aggData) => {
		if (!aggData) return "";

		const rounding = column.rounding || 0;

		switch (column.aggregate) {
			case "sum":
				return parseFloat(aggData.sum.toFixed(rounding)).toLocaleString();

			case "average":
				const averageValue = parseFloat(aggData.average.toFixed(rounding)).toLocaleString();
				if (column.dontShowTotal) {
					return <div>Avg: {averageValue}</div>;
				} else {
					const totalValue = parseFloat(aggData.sum.toFixed(rounding)).toLocaleString();
					return (
						<>
							<div>Total: {totalValue}</div>
							<div>Avg: {averageValue}</div>
						</>
					);
				}

			case "dividedAverage":
				const totalHours = parseFloat((aggData.sum / 60).toFixed(rounding)).toLocaleString();
				const averageHours = parseFloat((aggData.average / 60).toFixed(rounding)).toLocaleString();
				return (
					<>
						<div>Total: {totalHours} Hours</div>
						<div>Avg: {averageHours} Hours</div>
					</>
				);

			default:
				return "";
		}
	};

	// Conditionally render the footer
	const renderFooter = () => {
		if (!hasAggregations) return null; // Do not render the footer if no aggregation is needed

		let currentStickyLeft = 0;

		return (
			<tfoot>
				<tr style={{ position: "sticky", bottom: 0 }}>
					{headerGroups[0].headers
						.filter((h) => h.show !== false)
						.map((column) => {
							const aggData = aggregates[column.id];
							let displayValue = getDisplayValue(column, aggData);

							const colWidth = column.colWidth ? parseInt(column.colWidth, 10) : 0; // Parse the integer from colWidth
							const isStickyLeft = column.allClass != null && column.allClass.includes("tableStickyLeft");
							const isFooterTitle = column.allClass != null && column.allClass.includes("isFooterTitle");
							const footerTitle = isFooterTitle ? column.footerTitle : "";
							const leftValue = currentStickyLeft;
							currentStickyLeft += isStickyLeft ? colWidth : 0;

							if (isFooterTitle) {
								displayValue = footerTitle;
							}

							return (
								<th
									key={`${column.id}-footer`}
									className={isFooterTitle ? "text-end tableStickyLeft faintBorderRight" : (column.valueClass ?? "") + " " + (column.allClass ?? "")}
									style={{
										minWidth: column.colWidth ? column.colWidth : "auto",
										left: isStickyLeft ? leftValue : undefined,
										fontSize: "13px",
									}}>
									{displayValue}
								</th>
							);
						})}
				</tr>
			</tfoot>
		);
	};

	useEffect(() => {
		if (updateDefaultSortBy) {
			updateDefaultSortBy(sortBy);
		}
	}, [sortBy]);

	// Function to generate headers with potential hierarchy
	const generateHeaders = (columns, prefix = "", accessorPrefix = "") => {
		let headers = [];
		columns.forEach((column) => {
			const headerName = column.Header.trim();
			const displayedHeader = prefix ? `${prefix} - ${headerName}` : headerName;
			const accessor = accessorPrefix ? `${accessorPrefix}.${column.accessor}` : column.accessor;

			if (column.columns && column.columns.length > 0) {
				// Recursively collect headers from deeper nested columns
				headers.push(...generateHeaders(column.columns, displayedHeader, accessor));
			} else {
				// Split "Territory/Region" into two headers if necessary
				if (displayedHeader === "Territory/Region") {
					headers.push(["Territory", `territory`]);
					headers.push(["Region", `regionName`]);
				} else {
					headers.push([displayedHeader, accessor]);
				}
			}
		});
		return headers;
	};

	// Function to process all rows, including parent and child rows
	const processAllRowData = (item, accessors) => {
		const rowsData = [];

		// Extract data using accessors
		const rowData = accessors.map((accessor) => {
			try {
				return getNestedProperty(item, accessor);
			} catch (e) {
				return ""; // Return empty string if the path is incorrect or the value is undefined
			}
		});

		rowsData.push(rowData);

		// Process subRows recursively
		if (item.subRows && item.subRows.length > 0) {
			item.subRows.forEach((subItem) => {
				const subRowsData = processAllRowData(subItem, accessors);
				rowsData.push(...subRowsData);
			});
		}

		return rowsData;
	};

	// Function to recursively process row data to reach the bottom-most level and extract data
	const processBottomRowData = (item, accessors) => {
		// Recurse to the deepest subRow
		if (item.subRows && item.subRows.length > 0) {
			return item.subRows.flatMap((subItem) => processBottomRowData(subItem, accessors));
		} else {
			// At the deepest level, extract data using accessors
			return [
				accessors.map((accessor) => {
					try {
						// Handling nested properties safely, e.g., "parent.child"
						return getNestedProperty(item, accessor);
					} catch (e) {
						return ""; // Return empty string if the path is incorrect or the value is undefined
					}
				}),
			];
		}
	};

	// Prepare headers and row data for CSV export
	const headers = generateHeaders(columns);
	const accessors = headers.map((h) => h[1]); // Extract only the accessors from the headers

	// Helper function to handle nested properties safely
	function getNestedProperty(obj, path) {
		return path.split(".").reduce((acc, part) => acc && acc[part], obj) || "";
	}

	let csvRows;

	if (exportAllRows) {
		csvRows = data.flatMap((item) => processAllRowData(item, accessors));
	} else {
		csvRows = data.flatMap((item) => processBottomRowData(item, accessors));
	}

	const csvData = [
		headers.map((h) => h[0]), // Display names for the CSV headers
		...csvRows,
	];

	// Append the aggregation row if necessary
	if (hasAggregations) {
		const aggregationRow = [];
		headers.forEach(([displayedHeader, accessor]) => {
			const columnId = accessor;
			const aggData = aggregates[columnId];
			const displayValue = getDisplayValue({ id: columnId }, aggData);
			aggregationRow.push(displayValue);
		});
		csvData.push(aggregationRow);
	}

	function getHeader(column) {
		let retVal;

		retVal = [
			{
				id: column.id,
				isVisible: column.isVisible,
				value: column.Header,
				type: "string",
			},
		];
		return retVal;
	}

	function getExcel() {
		const config = {
			filename: updateFileName(fileName, columnFilterReport),
			sheet: {
				data: [],
			},
		};

		const dataSet = config.sheet.data;

		// Function to recursively find and return headers from the bottom-most columns
		function processHeaders(columns, prefix = "") {
			const headers = [];
			columns.forEach((column) => {
				// Determine the new prefix for the next level of recursion
				const headerName = column.Header.trim().length > 0 ? column.Header.trim() : "";
				const newPrefix = prefix ? prefix + " - " + headerName : headerName;

				if (column.columns && column.columns.length > 0) {
					// Recursively collect headers from deeper nested columns
					headers.push(...processHeaders(column.columns, newPrefix));
				} else {
					// Check to see if this is the Territory/Region column and it needs to be split

					let header = { ...getHeader(column), value: newPrefix };

					if (header.value === "Territory/Region") {
						headers.push({ ...header, value: "Territory" });
						headers.push({ ...header, value: "Region" });
					} else if (header[0].id === "expander") {
						// Don't push
					} else {
						// This column has no further nested columns, process this as a leaf header
						headers.push(header);
					}
				}
			});
			return headers;
		}

		var flattenedHeaders = [];

		// Process each header group and add its processed headers to the dataset
		headerGroups.forEach((headerGroup, headerGroupIndex) => {
			if (headerGroup.headers && headerGroupIndex === 0) {
				const headerRow = processHeaders(headerGroup.headers); // Process the headers recursively
				flattenedHeaders = headerGroup.headers;
				dataSet.push(headerRow); // Ensure this is the only header row pushed to the dataset
			}
		});

		// Function to process a single row
		function processRow(row) {
			const dataRow = [];

			// Ensure to only adjust the hierarchy for non-top-level items
			if (row.depth !== 0) {
				// Insert values with special handling for "Territory" and the insertion of "RegionName"
				Object.keys(row.values).forEach((key) => {
					if (key === "territory") {
						// Insert "Territory"
						dataRow.push({
							value: row.values[key],
							type: typeof row.values[key] === "number" ? "number" : "string",
						});
						if (isKpiManagement) {
							// Insert "RegionName" from row.original immediately after "Territory"
							const regionName = row.original.regionName || "N/A"; // Default if undefined
							dataRow.push({
								value: regionName,
								type: "string",
							});
						}
					} else if (key === "clinicName") {
						// Delay insertion of "ClinicName" to handle after "RegionName"
						const clinicName = row.values[key];
						// Now insert "ClinicName" here
						dataRow.push({
							value: clinicName,
							type: typeof clinicName === "number" ? "number" : "string",
						});
					} else if (key === "expander") {
						//Don't push Data within Expander columns, as it's just somewhere to put the expander triangle
					} else if (key === "regionName" && !isKpiManagement) {
						// Ignore "regionName" IF AND ONLY IF it's on the KPI dashboard since it's been handled already by the teritory
						dataRow.push({
							value: row.values[key],
							type: typeof row.values[key] === "number" ? "number" : "string",
						});
					} else {
						//Push Everything Else
						dataRow.push({
							value: row.values[key],
							type: typeof row.values[key] === "number" ? "number" : "string",
						});
					}
				});
			} else {
				// Process rows with depth 0 normally
				Object.keys(row.values).forEach((key) => {
					if (key !== "expander") {
						//console.log(key, typeof row.values[key], row.values[key]);
						let thisValue = row.values[key];
						//Check special formattors
						const thisHeader = flattenedHeaders.find((fh) => fh.id === key);
						
						if (thisHeader) {
							if (thisHeader.dateFormattor) {
								thisValue = dateFormat(thisValue, thisHeader.dateFormattor);
							}
						}

						dataRow.push({
							value: thisValue,
							type: typeof row.values[key] === "number" ? "number" : "string",
						});
					}
				});
			}
			dataSet.push(dataRow);
		}

		// Function to process all rows, including parent and child rows
		function processAllRows(row) {
			// Process the current row
			processRow(row);

			// Recursively process subRows if they exist
			if (row.subRows && row.subRows.length > 0) {
				row.subRows.forEach((subRow) => {
					processAllRows(subRow);
				});
			}
		}

		// Function to recursively find and process clinic-level rows
		function processSubRows(subRows) {
			subRows.forEach((subRow) => {
				if (subRow.subRows && subRow.subRows.length > 0) {
					// Recurse if there are more sub-rows
					processSubRows(subRow.subRows);
				} else {
					// Process the clinic level row
					processRow(subRow);
				}
			});
		}

		// Process rows depending on whether they have subRows or not
		if (rows.length > 0) {
			if (exportAllRows) {
				// Process all rows, including parent and child rows
				rows.forEach((row) => {
					processAllRows(row);
				});
			} else {
				// Existing logic to process only bottom-level rows
				rows.forEach((row) => {
					if (row.subRows && row.subRows.length > 0) {
						processSubRows(row.subRows); // Start recursion for hierarchical data
					} else {
						processRow(row); // Process non-hierarchical data
					}
				});
			}
		} else {
			dataSet.push([
				{
					value: "No data",
					type: "string",
				},
			]);
		}

		if (hasAggregations) {
			const aggregationRow = [];

			headerGroups[0].headers
				.filter((column) => column.show !== false)
				.forEach((column) => {
					const aggData = aggregates[column.id];
					const displayValue = getDisplayValue(column, aggData);

					if (column.id !== "expander") {
						aggregationRow.push({
							value: displayValue,
							type: typeof displayValue === "number" ? "number" : "string",
						});
					}
				});

			dataSet.push(aggregationRow);
		}

		return generateExcel(config);
	}

	const [selectedRowIndex, setSelectedRowIndex] = useState(null);

	const handleRowClick = (index) => {
		setSelectedRowIndex(index);
	};

	const [isAllExpanded, setIsAllExpanded] = useState(false);
	const handleToggleExpandAll = () => {
		const newIsExpanded = !isAllExpanded;
		setIsAllExpanded(newIsExpanded);
		tableInstance.toggleAllRowsExpanded(newIsExpanded);
	};

	return (
		<>
			{((data.length > 0 && allowGlobalFilter) || activeOnlyFilter) && (
				<div className="row my-2">
					{data.length > 0 && allowGlobalFilter && (
						<div className="col-3">
							<input
								type="text"
								value={globalFilter || ""}
								onChange={(e) => {
									setGlobalFilter(e.target.value);
									updateGlobalFilterValue(e.target.value);
									if (updatePageIndexValue) {
										updatePageIndexValue(0);
									}
								}}
								className="form-control"
							/>
						</div>
					)}
					{activeOnlyFilter && (
						<div className="col-3 align-self-center">
							<input
								type="checkbox"
								id="includeNoClinic"
								onChange={() => {
									filterList(!activeOnly);
									if (updatePageIndexValue) {
										updatePageIndexValue(0);
									}
								}}
								value={activeOnly}
								checked={activeOnly}
							/>
							<label className="form-check-label ps-1" htmlFor="includeNoClinic">
								Show Active Only
							</label>
						</div>
					)}
				</div>
			)}
			<div className="mb-3">
				<CSVLink className="btn btn-outline-secondary" filename={updateFileName(fileName, columnFilterReport)} data={csvData}>
					<i className="fas fa-file-csv"></i> Save as CSV
				</CSVLink>
				<button className="btn btn-outline-secondary ms-3" onClick={getExcel}>
					<i className="fas fa-file-excel"></i> Export to Excel
				</button>
			</div>
			{data.length > 0 ? (
				<>
					<div className="border customTable" style={{ maxHeight: tableHeight ?? `calc(100% - ${reduceWidth ? reduceWidth : "120px"})`, overflowY: "scroll" }}>
						<table {...getTableProps()} className="table table-hover table-striped mb-0 customTable">
							<thead className="sticky-top bg-white">
								{headerGroups.map((headerGroup, k) => {
									const isTopHeader = k === 0;
									let currentStickyLeft = 0;

									return (
										// eslint-disable-next-line react/jsx-key
										<tr {...headerGroup.getHeaderGroupProps()} key={k} data-headerrow={fileName + "-Header-" + k}>
											{headerGroup.headers
												.filter((h) => h.show !== false)
												.map((column, l) => {
													const parentColumns = column.parent ? column.parent.columns : null;
													const isLastHeaderInSegment = column.parent && parentColumns.findIndex((c) => c.Header === column.Header) + 1 === parentColumns.length;

													const { key, ...restColumnProps } = column.getHeaderProps();

													const colWidth = column.colWidth ? parseInt(column.colWidth, 10) : 0; // Parse the integer from colWidth
													const isStickyLeft = column.allClass != null && column.allClass.includes("tableStickyLeft");
													const leftValue = currentStickyLeft;
													currentStickyLeft += isStickyLeft ? colWidth : 0;

													return (
														// eslint-disable-next-line react/jsx-key
														<th
															className={`align-top ${column.headerClass ? column.headerClass : ""} ${column.allClass ? column.allClass : ""} ${
																isTopHeader || isLastHeaderInSegment ? "faintBorderRight" : ""
															}`}
															key={fileName + "-" + key}
															style={{
																minWidth: column.colWidth ? column.colWidth : "auto",
																left: isStickyLeft ? leftValue : undefined,
															}}
															{...restColumnProps}>
															{manualSortBy ? (
																<div className="align-top">
																	<span {...column.getSortByToggleProps()}>
																		{column.render("Header")}
																		{column.isSorted ? (
																			<FontAwesomeIcon
																				icon={column.isSortedDesc ? "arrow-up-short-wide" : "arrow-down-short-wide"}
																				size="2xs"
																				className="float-end"
																				style={{ fontSize: "12px" }}
																			/>
																		) : (
																			""
																		)}
																	</span>
																</div>
															) : (
																column.render("Header")
															)}
															{column.manualFilter && (
																<div className={column.filterInputType === "check" ? "text-center pt-1" : ""}>{column.canFilter ? column.render("Filter") : null}</div>
															)}
															{column.id === "expander" && (
																<FontAwesomeIcon icon="triangle" onClick={handleToggleExpandAll} rotation={isAllExpanded ? 180 : 90} size="2xs" />
															)}
														</th>
													);
												})}
											{viewDetailsBtn && <th style={{ width: "50px" }}></th>}
											{deleteBtn && configData && configData.primaryKeyField && <th style={{ width: "50px" }}></th>}
										</tr>
									);
								})}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row);
									let currentStickyLeft = 0;
									const { key, ...restRowProps } = row.getRowProps();
									const rowDepth = row.depth;
									const rowKey = key;

									let nonStick = false;
									if (row.original && row.original.formatters && row.original.formatters.valueClass && row.original.formatters.valueClass.includes("noSticky")) {
										nonStick = true;
									}

									return (
										<tr
											key={key}
											className={`${selectedRowIndex === key ? "table-active" : ""}`}
											style={{ position: nonStick ? "" : "sticky", top: nonStick ? 0 : (2 + rowDepth) * 30 }}
											onClick={() => handleRowClick(key)}
											{...restRowProps}>
											{row.cells
												.filter((c) => c.column.show !== false)
												.map((cell) => {
													const colWidth = cell.column.colWidth ? parseInt(cell.column.colWidth, 10) : 0; // Parse the integer from colWidth
													const isStickyLeft = cell.column.allClass != null && cell.column.allClass.includes("tableStickyLeft");
													const leftValue = currentStickyLeft;
													currentStickyLeft += isStickyLeft ? colWidth : 0;

													const parentColumns = cell.column.parent ? cell.column.parent.columns : null;
													const isLastHeaderInSegment = cell.column.parent && parentColumns.findIndex((c) => c.Header === cell.column.Header) + 1 === parentColumns.length;

													const { key, ...restCellProps } = cell.getCellProps();
													return (
														<td
															className={`${selectedRowIndex === rowKey ? "table-active" : ""} ${cell.column.valueClass ? cell.column.valueClass : ""} ${
																cell.column.allClass ? cell.column.allClass : ""
															} ${isLastHeaderInSegment ? "faintBorderRight" : ""}`}
															key={key}
															style={{
																left: isStickyLeft ? leftValue : undefined,
															}}
															{...restCellProps}>
															{cell.render("Cell")}
														</td>
													);
												})}
										</tr>
									);
								})}
							</tbody>
							{totalAggregateRow != null ? (
								<tfoot>
									{footerGroups.map((group, index) => {
										const { key, ...restFooterGroupProps } = group.getFooterGroupProps();
										let currentStickyLeft = 0;
										if (index === 0) {
											return (
												<tr key={key} {...restFooterGroupProps} style={{ position: "sticky", bottom: 0 }}>
													{group.headers.map((column) => {
														const colWidth = column.colWidth ? parseInt(column.colWidth, 10) : 0; // Parse the integer from colWidth
														const isStickyLeft = column.allClass != null && column.allClass.includes("tableStickyLeft");
														const leftValue = currentStickyLeft;
														currentStickyLeft += isStickyLeft ? colWidth : 0;

														const { key, ...restFooterProps } = column.getFooterProps();
														let footerContent = "";
														if (["territory", "clinicName", "source", "regionName", "rcCode"].includes(column.id)) {
															if (column.id === "rcCode") {
																footerContent = "TOTAL"; // Set as 'TOTAL' for the 'territory' column
															} else {
																footerContent = ""; // Set as empty for other special columns
															}
														} else {
															// Default behavior: match column.id with the value from totalAggregateRow
															footerContent = amountFormat(totalAggregateRow[column.id], {
																decimalPoint: 0,
																format: "round",
															});
														}

														const parentColumns = column.parent ? column.parent.columns : null;
														const isLastHeaderInSegment = column.parent && parentColumns.findIndex((c) => c.Header === column.Header) + 1 === parentColumns.length;

														return (
															<th
																className={`text-end font-weight-bold ${isLastHeaderInSegment ? "faintBorderRight" : ""} ${column.allClass ? column.allClass : ""}`}
																key={key}
																style={{
																	left: isStickyLeft ? leftValue : undefined,
																	fontSize: "13px",
																}}
																{...restFooterProps}>
																{footerContent}
															</th>
														);
													})}
												</tr>
											);
										} else {
											return null;
										}
									})}
								</tfoot>
							) : (
								<>{renderFooter()}</>
							)}
						</table>
					</div>
					{allowPagination === "yes" && (
						<nav className="mt-3 d-flex gap-2 justify-content-center align-items-center">
							<span>
								Record(s): {rows.length > 0 ? pageSize * pageIndex + 1 : 0} - {pageSize * (pageIndex + 1) > rows.length ? rows.length : pageSize * (pageIndex + 1)} of {rows.length}
							</span>
							<ul className="pagination m-0">
								<li className="page-item">
									<button
										className={`page-link ${canPreviousPage ? "" : "disabled"}`}
										onClick={() => {
											gotoPage(0);
											if (updatePageIndexValue) {
												updatePageIndexValue(0);
											}
										}}>
										<span className="fa-solid fa-angles-left" />
									</button>
								</li>
								<li className="page-item">
									<button
										className={`page-link ${canPreviousPage ? "" : "disabled"}`}
										onClick={() => {
											previousPage();
											if (updatePageIndexValue) {
												updatePageIndexValue(pageIndex - 1);
											}
										}}>
										<span className="fa-solid fa-angle-left" />
									</button>
								</li>
								<li className="page-item">
									<div className="page-link">
										Page{" "}
										<strong>
											{pageIndex + 1} of {pageCount}
										</strong>
									</div>
								</li>
								<li className="page-item">
									<button
										className={`page-link ${canNextPage ? "" : "disabled"}`}
										onClick={() => {
											nextPage();
											if (updatePageIndexValue) {
												updatePageIndexValue(pageIndex + 1);
											}
										}}>
										<span className="fa-solid fa-angle-right" />
									</button>
								</li>
								<li className="page-item">
									<button
										className={`page-link ${canNextPage ? "" : "disabled"}`}
										onClick={() => {
											gotoPage(pageCount - 1);
											if (updatePageIndexValue) {
												updatePageIndexValue(pageCount - 1);
											}
										}}>
										<span className="fa-solid fa-angles-right" />
									</button>
								</li>
							</ul>

							<select className="form-select w-auto" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
								{[30, 50, 100, 200, 500, 1000].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</select>
						</nav>
					)}
				</>
			) : (
				<div className="text-center p-2 text-danger">"No data found."</div>
			)}
		</>
	);
};

export default Table;
