import React, { useState, useEffect, useMemo, useRef } from "react";
import { Row, Col, Card, Spinner, Form, Badge, Button } from "react-bootstrap";
import { apiRequest } from "./api/Request";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const PermissionItem = ({ perm, isGranted, isInherited, isUngrantedParentWithGrantedChildren, level, onChange }) => {
	const checkboxRef = useRef(null);

	useEffect(() => {
		if (checkboxRef.current) {
			checkboxRef.current.indeterminate = isUngrantedParentWithGrantedChildren;
		}
	}, [isUngrantedParentWithGrantedChildren]);

	return (
		<div style={{ marginLeft: (level + 1) * 20 + "px" }}>
			<Form.Check type="checkbox">
				<Form.Check.Input
					type="checkbox"
					id={`perm-${perm.id}`}
					checked={!!(isGranted || isInherited)}
					disabled={isInherited}
					ref={checkboxRef}
					onChange={onChange}
				/>
				<Form.Check.Label htmlFor={`perm-${perm.id}`}>{perm.name}</Form.Check.Label>
			</Form.Check>
		</div>
	);
};

PermissionItem.propTypes = {
	perm: PropTypes.object.isRequired,
	isGranted: PropTypes.bool.isRequired,
	isInherited: PropTypes.bool.isRequired,
	isUngrantedParentWithGrantedChildren: PropTypes.bool.isRequired,
	level: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

const GroupPermissionsEditor = ({ groupId, groupPermissions, updatePermissions }) => {
	const [gPerms, setGPerms] = useState([]);
	const [availablePermissions, setAvailablePermissions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [initialPermIds, setInitialPermIds] = useState([]);

	useEffect(() => {
		const fetchPermissions = async () => {
			setIsLoading(true);
			const response = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-permissions", "get");
			const permissions = response.data.map((permission) => ({
				id: permission.securityPermissionId,
				name: permission.permissionFriendlyName,
				parentId: permission.parentPermissionId,
				groups: JSON.parse(permission.groups || "[]"),
			}));

			setAvailablePermissions(permissions);
			setIsLoading(false);
		};
		fetchPermissions();
	}, [groupId]);

	// Set initial permissions only once when the groupId changes (i.e., new group)
	useEffect(() => {
		let initialPermissionIds = [];
		if (groupPermissions != null) {
			if (typeof groupPermissions === "string") {
				initialPermissionIds = JSON.parse(groupPermissions).map(Number);
			} else if (Array.isArray(groupPermissions)) {
				initialPermissionIds = groupPermissions.map(Number);
			}
		}
		setInitialPermIds(initialPermissionIds);
		setGPerms(initialPermissionIds);
	}, [groupId]);

	// If groupPermissions changes due to parent updates (e.g. saving), just sync gPerms.
	useEffect(() => {
		if (groupPermissions != null) {
			let currentPermIds = Array.isArray(groupPermissions)
				? groupPermissions.map(Number)
				: typeof groupPermissions === "string"
				? JSON.parse(groupPermissions).map(Number)
				: [];
			setGPerms(currentPermIds);
		}
	}, [groupPermissions]);

	const permissionHierarchy = useMemo(() => {
		const hierarchy = {};
		availablePermissions.forEach((perm) => {
			const parentId = perm.parentId || null;
			if (!hierarchy[parentId]) {
				hierarchy[parentId] = [];
			}
			hierarchy[parentId].push(perm);
		});

		Object.keys(hierarchy).forEach((parentId) => {
			hierarchy[parentId].sort((a, b) => a.name.localeCompare(b.name));
		});

		return hierarchy;
	}, [availablePermissions]);

	const getAllDescendants = (parentId) => {
		const result = [];
		const children = permissionHierarchy[parentId] || [];
		for (const child of children) {
			result.push(child.id);
			result.push(...getAllDescendants(child.id));
		}
		return result;
	};

	const renderPermissions = (parentId = null, level = 0) => {
		const perms = permissionHierarchy[parentId];
		if (!perms) return null;

		return perms.map((perm) => {
			const isGranted = gPerms.includes(perm.id);
			const parentGranted = perm.parentId ? gPerms.includes(perm.parentId) : false;
			const isInherited = parentGranted;

			const children = permissionHierarchy[perm.id] || [];
			const isUngrantedParentWithGrantedChildren = !isGranted && children.some((child) => gPerms.includes(child.id));

			const handleChange = (e) => {
				const isChecked = e.target.checked;
				let updatedPerms = [...gPerms];

				if (isChecked) {
					const descendants = getAllDescendants(perm.id);
					updatedPerms = updatedPerms.filter((id) => !descendants.includes(id));
					if (!updatedPerms.includes(perm.id)) {
						updatedPerms.push(perm.id);
					}
				} else {
					updatedPerms = updatedPerms.filter((id) => id !== perm.id);
				}

				setGPerms(updatedPerms);
				updatePermissions(updatedPerms);
			};

			return (
				<React.Fragment key={perm.id}>
					<PermissionItem
						perm={perm}
						isGranted={isGranted}
						isInherited={isInherited}
						isUngrantedParentWithGrantedChildren={isUngrantedParentWithGrantedChildren}
						level={level}
						onChange={handleChange}
					/>
					{children.length > 0 && renderPermissions(perm.id, level + 1)}
				</React.Fragment>
			);
		});
	};

	const handleRemovePermission = (id) => {
		const newSelectedPermissions = gPerms.filter((permId) => permId !== id);
		setGPerms(newSelectedPermissions);
		updatePermissions(newSelectedPermissions);
	};

	const newlyAddedPermissions = gPerms.filter((id) => !initialPermIds.includes(id));
	const newlyRemovedPermissions = initialPermIds.filter((id) => !gPerms.includes(id));

	const selectedPermDetails = gPerms
		.map((permId) => availablePermissions.find((perm) => perm.id === permId))
		.filter(Boolean);

	const removedPermDetails = newlyRemovedPermissions
		.map((permId) => availablePermissions.find((perm) => perm.id === permId))
		.filter(Boolean);

	const displayedPerms = [...selectedPermDetails, ...removedPermDetails];

	const renderBadge = (status) => {
		const addedBadgeStyle = {
			fontSize: "10px",
			padding: "3px 7px",
			borderRadius: "5px",
			marginLeft: "5px",
			backgroundColor: "#D9FBF2",
			color: "#3BAF8D",
			border: "1px solid #3BAF8D",
		};

		const removedBadgeStyle = {
			fontSize: "10px",
			padding: "3px 7px",
			borderRadius: "5px",
			marginLeft: "5px",
			backgroundColor: "#FFEFEF",
			color: "#D9534F",
			border: "1px solid #D9534F",
		};

		return (
			<Badge bg="" style={status === "ADDED" ? addedBadgeStyle : removedBadgeStyle}>
				{status}
			</Badge>
		);
	};

	return (
		<Row className="w-100">
			<Col>
				<Card className="mx-3">
					<Card.Header>
						<Row>
							<Col>
								<h5>Permission Mapping</h5>
							</Col>
						</Row>
					</Card.Header>
					<Card.Body>
						<Row>
							<Col md={6}>
								<Card className="h-100">
									<Card.Header>Group Permissions</Card.Header>
									<Card.Body>
										{isLoading ? (
											<Spinner animation="border" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										) : (
											<div className="options-dropdown keepOpen">{renderPermissions()}</div>
										)}
									</Card.Body>
								</Card>
							</Col>
							<Col md={6}>
								<Card className="h-100">
									<Card.Header>Selected Permissions</Card.Header>
									<Card.Body>
										{isLoading ? (
											<Spinner animation="border" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										) : (
											<div className="selectedPermissionOptions">
												{displayedPerms.map((perm) => {
													const isAdded = newlyAddedPermissions.includes(perm.id);
													const isRemoved = newlyRemovedPermissions.includes(perm.id);
													return (
														<div
															key={"selectedPerm_" + perm.id}
															className={`d-flex align-items-center mb-2 ${isRemoved ? "text-muted" : ""}`}
														>
															{!isRemoved && (
																<Button
																	className="text-center me-2 noBorder"
																	size="sm"
																	variant="outline-danger"
																	onClick={() => handleRemovePermission(perm.id)}
																>
																	<FontAwesomeIcon icon="trash-xmark" />
																</Button>
															)}
															<strong style={isRemoved ? { textDecoration: "line-through" } : {}}>
																{perm.name}
															</strong>
															{isAdded && renderBadge("ADDED")}
															{isRemoved && renderBadge("REMOVED")}
														</div>
													);
												})}
											</div>
										)}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

GroupPermissionsEditor.propTypes = {
	groupPermissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.string]),
	updatePermissions: PropTypes.func.isRequired,
	groupId: PropTypes.number.isRequired,
};

export default GroupPermissionsEditor;
